import React, { useState } from "react";
import { useTranslation } from "react-i18next";

function LanguageSwitcher() {
  const { t, i18n } = useTranslation();
  const [popoverOpen, setPopoverOpen] = useState(false);
  const currentLanguage = localStorage.getItem("lang");

  const languages = [
    { id: "en", title: t("english"), flag: "en" },
    { id: "fr", title: t("french"), flag: "fr" },
    //{/* id: "jp", title: t("japanese"), flag: "jp" */},
  ];

  const handleLanguageChange = (lng) => {
    /*
    Change Language
    */
    i18n.changeLanguage(lng.id);
    localStorage.setItem("lang", lng.id);

    setPopoverOpen(false);
  };

  return (
    <div className="flag-list">
      <div className="h-64 w-64" onClick={() => setPopoverOpen(!popoverOpen)}>
        <img
          className="flag-selected"
          src={`images/flags/${currentLanguage}.svg`}
          alt={currentLanguage}
        />
      </div>
      {popoverOpen && (
        <div className="language-options">
          {languages.map((lng) => (
            <div
              key={lng.id}
              onClick={() => handleLanguageChange(lng)}
              className="language-option"
            >
              <div className="min-w-40">
                <img
                  className="min-w-20"
                  src={`images/flags/${lng.flag}.svg`}
                  alt={lng.title}
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default LanguageSwitcher;
